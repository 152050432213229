const initialState = {
  suggestions: [],
  selectedSuggestion: null,
  stats: {
    allUnRead: 0,
    advUnRead: 0,
    infUnRead: 0,
  },
};

const suggestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SUGGESTIONS":
      return {
        ...state,
        suggestions: action.data.list,
        stats: {
          ...state.stats,
          allUnRead:  action.data.total_advertiser_unread+ action.data.total_influencer_unread,
          advUnRead: action.data.total_advertiser_unread,
          infUnRead: action.data.total_influencer_unread,
        },
      };
    case "GET_ONE_SUGGESTION":
      return { ...state, selectedSuggestion: action.data.data };
    default:
      return state;
  }
};

export default suggestionsReducer;
