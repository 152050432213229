const initialState = {
  contactMessages: [],
  contactMessageDetails: null,
 
};

const contactMessagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONTACT_MESSAGES":
      return {
        ...state,
        contactMessages: action.data.list,
       
      };
    case "GET_ONE_CONTACT_MESSAGE":
      return { ...state, contactMessageDetails: action.data.data };
    default:
      return state;
  }
};

export default contactMessagesReducer;
