//@ts-check
import * as Sentry from "@sentry/react";

export const initSentry = () => {
    if (process.env.REACT_APP_ENABLE_SENTRY === 'true' && process.env.REACT_APP_SENTRY_DSN) {

        // Sentry.init({
        //     dsn: process.env.REACT_APP_SENTRY_DSN,
        //     integrations: [
        //         new Sentry.BrowserTracing(),
        //         new Sentry.Replay(),
        //     ],
        //     // Performance Monitoring
        //     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        //     // Session Replay
        //     autoSessionTracking: true,
        // });

        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [
                new Sentry.BrowserTracing(
                    // {
                    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                    // tracePropagationTargets: ["localhost"],
                    // See docs for support of different versions of variation of react router
                    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                    // routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    //   React.useEffect,
                    //   useLocation,
                    //   useNavigationType,
                    //   createRoutesFromChildren,
                    //   matchRoutes
                    // ),
                    // }
                ),
                new Sentry.Replay()
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            tracesSampleRate: 1.0,

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

    }
};
